import React from "react";
import { useEffect, useState } from "react";
import "./App.css";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

var visitedAt = new Date().getTime(); // 방문 시간
const Product = (props) => {
    const [loading, setLoading] = useState(true);
    const [searchParams] = useSearchParams();

    const gubun = searchParams.get("gubun");
    const id = searchParams.get("id");
    let platform;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("https://nakmat.online/admin/fishTest");

                console.log(response.data.result.platform);
                if (response.data.result.isMobile) {
                    if (response.data.result.platform === "iPhone") {
                        console.log("아이폰 타는지d");
                        setTimeout(function () {
                            if (new Date().getTime() - visitedAt < 7000) {
                                window.location.href = "https://apps.apple.com/kr/app/id1665121067";
                            }
                        }, 3000);

                        setTimeout(function () {
                            window.location.href = `nakmat://${gubun}?id=${id}`;
                        }, 0);
                    } else if (response.data.result.platform === "Android") {
                        setTimeout(function () {
                            if (new Date().getTime() - visitedAt < 2000) {
                                const link = `intent://${gubun}?id=${id}#Intent;scheme=nakmat;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=com.connectzero.fishingtaste.android;end`;
                                window.location.href = link;
                            }
                        }, 500);

                        setTimeout(function () {
                            window.location.href = `nakmat://${gubun}?id=${id}`;
                        }, 0);
                        console.log("안드로이드 타는지");
                    }
                } else {
                    setLoading(false);
                }
            } catch (e) {
                console.log(e);
                setLoading(false);
            }
        };
        fetchData();
    }, [gubun, id]);

    var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기]
    console.log(varUA);

    const isPc = useMediaQuery({
        query: "(min-width:1024px)",
    });
    const isTablet = useMediaQuery({
        query: "(min-width:768px) and (max-width:1023px)",
    });
    const isMobile = useMediaQuery({
        query: "(max-width:767px)",
    });

    const homePage = function () {
        window.location.href = "https://nakmat.com";
    };

    const appDownloadWindo = function () {
        window.location.href = "https://play.google.com/store/apps/details?id=com.connectzero.fishingtaste.android&hl=ko-KR";
    };

    const appDownloadMac = function () {
        window.location.href = "https://apps.apple.com/kr/app/id1665121067";
    };

    const mobileDownlad = function () {
        if (navigator.userAgent.match(/iPhone/i)) {
            window.location.href = "https://apps.apple.com/kr/app/id1665121067";
        }

        if (navigator.userAgent.match(/Android/i)) {
            const link = `intent://${gubun}?id=${id}#Intent;scheme=nakmat;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=com.connectzero.fishingtaste.android;end`;
            window.location.href = link;
        }
    };

    const mobileStart = function () {
        window.location.href = `nakmat://${gubun}?id=${id}`;
    };

    if (loading) {
        return (
            <div className="App">
                {isPc && (
                    <div>
                        <div style={{ position: "absolute", bottom: "25%", right: "20%", width: "100%" }}>
                            <div className="wep-log">
                                <h3 style={{ fontSize: 40, fontFamily: "Noto Sans KR" }}>낚시의 맛에 빠지다</h3>
                                <img
                                    style={{
                                        width: "30%", // 이미지의 가로 크기를 100%로 설정
                                        height: "auto", // 높이를 자동으로 조정하여 원래 비율을 유지
                                    }}
                                    src={"https://storage.googleapis.com/nakmat_fishs/admin-wep/logo_main2.png"}
                                    alt="nct"
                                />
                            </div>

                            <div className="item">
                                <button
                                    onClick={appDownloadMac}
                                    style={{
                                        width: 400,
                                        height: 50,
                                        color: "#fff",
                                        backgroundColor: "black",
                                        border: 0,
                                        borderRadius: "30px",
                                    }}
                                >
                                    <span style={{ fontSize: 20, fontFamily: "Nanum Gothic" }}> IOS용 앱 다운로드 </span>
                                </button>
                            </div>

                            <div className="item">
                                <button
                                    onClick={appDownloadWindo}
                                    style={{
                                        width: 400,
                                        height: 50,
                                        color: "#fff",
                                        backgroundColor: "black",
                                        border: 0,
                                        borderRadius: "30px",
                                    }}
                                >
                                    <span style={{ fontSize: 20, fontFamily: "Nanum Gothic" }}>안드로이드용 앱 다운로드 </span>
                                </button>
                            </div>

                            <div className="item">
                                <button
                                    onClick={homePage}
                                    style={{
                                        width: 400,
                                        height: 50,
                                        color: "#fff",
                                        backgroundColor: "black",
                                        border: 0,
                                        borderRadius: "30px",
                                        margin: "10px;",
                                    }}
                                >
                                    <span style={{ fontSize: 20, fontFamily: "Nanum Gothic" }}>홈페이지 바로 가기 </span>
                                </button>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <img
                                style={{
                                    width: "49%", // 이미지의 가로 크기를 100%로 설정
                                    height: "auto", // 높이를 자동으로 조정하여 원래 비율을 유지
                                }}
                                src={"https://storage.googleapis.com/nakmat_fishs/admin-wep/nakmatmain.png"}
                                alt="nct"
                            />
                        </div>
                    </div>
                )}
                {isTablet && (
                    <div>
                        <div className="logo">
                            <img
                                style={{
                                    width: "70%", // 이미지의 가로 크기를 100%로 설정
                                    height: "auto", // 높이를 자동으로 조정하여 원래 비율을 유지
                                }}
                                src={"https://storage.googleapis.com/nakmat_fishs/admin-wep/logo_main2.png"}
                                alt="nct"
                                className="gradient-image"
                            />
                        </div>
                        <h3 style={{ fontSize: 30, fontFamily: "Noto Sans KR" }}>낚시의 맛에 빠지다</h3>
                        <div className="wrapper">
                            <div>
                                <div className="item">
                                    <button
                                        onClick={mobileDownlad}
                                        style={{ width: 300, height: 50, color: "#fff", backgroundColor: "black", border: 0, borderRadius: "30px" }}
                                    >
                                        <span style={{ fontSize: 20, fontFamily: "Nanum Gothic" }}>앱 다운로드 </span>
                                    </button>
                                </div>
                                <div className="item">
                                    <button
                                        onClick={mobileStart}
                                        style={{ width: 300, height: 50, color: "#fff", backgroundColor: "black", border: 0, borderRadius: "30px" }}
                                    >
                                        <span style={{ fontSize: 20, fontFamily: "Nanum Gothic" }}>낚시의 맛 실행하기 </span>
                                    </button>
                                </div>
                            </div>
                            <div className="image-container" style={{ position: "absolute", right: "0px", bottom: "0px" }}>
                                <img
                                    style={{
                                        width: "100%", // 이미지의 가로 크기를 100%로 설정
                                        height: "auto", // 높이를 자동으로 조정하여 원래 비율을 유지
                                    }}
                                    src={"https://storage.googleapis.com/nakmat_fishs/admin-wep/nakmatmain.png"}
                                    alt="nct"
                                    className="gradient-image"
                                />
                            </div>
                        </div>
                    </div>
                )}
                {isMobile && (
                    <div>
                        <div className="logo">
                            <img
                                style={{
                                    width: "70%", // 이미지의 가로 크기를 100%로 설정
                                    height: "auto", // 높이를 자동으로 조정하여 원래 비율을 유지
                                }}
                                src={"https://storage.googleapis.com/nakmat_fishs/admin-wep/logo_main2.png"}
                                alt="nct"
                                className="gradient-image"
                            />
                        </div>
                        <h3 style={{ fontSize: 20, fontFamily: "Noto Sans KR" }}>낚시의 맛에 빠지다</h3>
                        <div className="wrapper">
                            <div>
                                <div className="item">
                                    <button
                                        onClick={mobileDownlad}
                                        style={{
                                            width: 300,
                                            height: 50,
                                            color: "#fff",
                                            backgroundColor: "black",
                                            border: 0,
                                            borderRadius: "30px",
                                        }}
                                    >
                                        <span style={{ fontSize: 20, fontFamily: "Nanum Gothic" }}>앱 다운로드 </span>
                                    </button>
                                </div>
                                <div className="item">
                                    <button
                                        onClick={mobileStart}
                                        style={{ width: 300, height: 50, color: "#fff", backgroundColor: "black", border: 0, borderRadius: "30px" }}
                                    >
                                        <span style={{ fontSize: 20, fontFamily: "Nanum Gothic" }}>낚시의 맛 실행하기 </span>
                                    </button>
                                </div>
                            </div>
                            <div className="image-container" style={{ position: "absolute", right: "0px", bottom: "0px" }}>
                                <img
                                    style={{
                                        width: "100%", // 이미지의 가로 크기를 100%로 설정
                                        height: "auto", // 높이를 자동으로 조정하여 원래 비율을 유지
                                    }}
                                    src={"https://storage.googleapis.com/nakmat_fishs/admin-wep/nakmatmain.png"}
                                    alt="nct"
                                    className="gradient-image"
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className="App">
            {isPc && (
                <div>
                    <div style={{ position: "absolute", bottom: "25%", right: "20%", width: "100%" }}>
                        <div className="wep-log">
                            <h3 style={{ fontSize: 40, fontFamily: "Noto Sans KR" }}>낚시의 맛에 빠지다</h3>
                            <img
                                style={{
                                    width: "30%", // 이미지의 가로 크기를 100%로 설정
                                    height: "auto", // 높이를 자동으로 조정하여 원래 비율을 유지
                                }}
                                src={"https://storage.googleapis.com/nakmat_fishs/admin-wep/logo_main2.png"}
                                alt="nct"
                            />
                        </div>

                        <div className="item">
                            <button
                                onClick={appDownloadMac}
                                style={{
                                    width: 400,
                                    height: 50,
                                    color: "#fff",
                                    backgroundColor: "black",
                                    border: 0,
                                    borderRadius: "30px",
                                }}
                            >
                                <span style={{ fontSize: 20, fontFamily: "Nanum Gothic" }}> IOS용 앱 다운로드 </span>
                            </button>
                        </div>

                        <div className="item">
                            <button
                                onClick={appDownloadWindo}
                                style={{
                                    width: 400,
                                    height: 50,
                                    color: "#fff",
                                    backgroundColor: "black",
                                    border: 0,
                                    borderRadius: "30px",
                                }}
                            >
                                <span style={{ fontSize: 20, fontFamily: "Nanum Gothic" }}>안드로이드용 앱 다운로드 </span>
                            </button>
                        </div>

                        <div className="item">
                            <button
                                onClick={homePage}
                                style={{
                                    width: 400,
                                    height: 50,
                                    color: "#fff",
                                    backgroundColor: "black",
                                    border: 0,
                                    borderRadius: "30px",
                                    margin: "10px;",
                                }}
                            >
                                <span style={{ fontSize: 20, fontFamily: "Nanum Gothic" }}>홈페이지 바로 가기 </span>
                            </button>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <img
                            style={{
                                width: "49%", // 이미지의 가로 크기를 100%로 설정
                                height: "auto", // 높이를 자동으로 조정하여 원래 비율을 유지
                            }}
                            src={"https://storage.googleapis.com/nakmat_fishs/admin-wep/nakmatmain.png"}
                            alt="nct"
                        />
                    </div>
                </div>
            )}
            {isTablet && (
                <div>
                    <div className="logo">
                        <img
                            style={{
                                width: "70%", // 이미지의 가로 크기를 100%로 설정
                                height: "auto", // 높이를 자동으로 조정하여 원래 비율을 유지
                            }}
                            src={"https://storage.googleapis.com/nakmat_fishs/admin-wep/logo_main2.png"}
                            alt="nct"
                            className="gradient-image"
                        />
                    </div>
                    <h3 style={{ fontSize: 30, fontFamily: "Noto Sans KR" }}>낚시의 맛에 빠지다</h3>
                    <div className="wrapper">
                        <div>
                            <div className="item">
                                <button
                                    onClick={mobileDownlad}
                                    style={{ width: 300, height: 50, color: "#fff", backgroundColor: "black", border: 0, borderRadius: "30px" }}
                                >
                                    <span style={{ fontSize: 20, fontFamily: "Nanum Gothic" }}>앱 다운로드 </span>
                                </button>
                            </div>
                            <div className="item">
                                <button
                                    onClick={mobileStart}
                                    style={{ width: 300, height: 50, color: "#fff", backgroundColor: "black", border: 0, borderRadius: "30px" }}
                                >
                                    <span style={{ fontSize: 20, fontFamily: "Nanum Gothic" }}>낚시의 맛 실행하기 </span>
                                </button>
                            </div>
                        </div>
                        <div className="image-container" style={{ position: "absolute", right: "0px", bottom: "0px" }}>
                            <img
                                style={{
                                    width: "100%", // 이미지의 가로 크기를 100%로 설정
                                    height: "auto", // 높이를 자동으로 조정하여 원래 비율을 유지
                                }}
                                src={"https://storage.googleapis.com/nakmat_fishs/admin-wep/nakmatmain.png"}
                                alt="nct"
                                className="gradient-image"
                            />
                        </div>
                    </div>
                </div>
            )}
            {isMobile && (
                <div>
                    <div className="logo">
                        <img
                            style={{
                                width: "70%", // 이미지의 가로 크기를 100%로 설정
                                height: "auto", // 높이를 자동으로 조정하여 원래 비율을 유지
                            }}
                            src={"https://storage.googleapis.com/nakmat_fishs/admin-wep/logo_main2.png"}
                            alt="nct"
                            className="gradient-image"
                        />
                    </div>
                    <h3 style={{ fontSize: 20, fontFamily: "Noto Sans KR" }}>낚시의 맛에 빠지다</h3>
                    <div className="wrapper">
                        <div>
                            <div className="item">
                                <button
                                    onClick={mobileDownlad}
                                    style={{
                                        width: 300,
                                        height: 50,
                                        color: "#fff",
                                        backgroundColor: "black",
                                        border: 0,
                                        borderRadius: "30px",
                                    }}
                                >
                                    <span style={{ fontSize: 20, fontFamily: "Nanum Gothic" }}>앱 다운로드 </span>
                                </button>
                            </div>
                            <div className="item">
                                <button
                                    onClick={mobileStart}
                                    style={{ width: 300, height: 50, color: "#fff", backgroundColor: "black", border: 0, borderRadius: "30px" }}
                                >
                                    <span style={{ fontSize: 20, fontFamily: "Nanum Gothic" }}>낚시의 맛 실행하기 </span>
                                </button>
                            </div>
                        </div>
                        <div className="image-container" style={{ position: "absolute", right: "0px", bottom: "0px" }}>
                            <img
                                style={{
                                    width: "100%", // 이미지의 가로 크기를 100%로 설정
                                    height: "auto", // 높이를 자동으로 조정하여 원래 비율을 유지
                                }}
                                src={"https://storage.googleapis.com/nakmat_fishs/admin-wep/nakmatmain.png"}
                                alt="nct"
                                className="gradient-image"
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Product;

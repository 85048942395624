import React from "react";
import { useState, useEffect } from "react";
import "./App.css";
import axios from "axios";
import Modal from "react-modal";

const PhoneVerification = () => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [error, setError] = useState("");
    const [verificationMessage, setVerificationMessage] = useState("인증요청");
    const [countdown, setCountdown] = useState(180); // 3분 (180초)
    const [isResend, setIsResend] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [isChecked, setIsChecked] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [showUserInfo, setShowUserInfo] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleVerifyPhone = async () => {
        if (isResend) {
            await handleSendCode();
        } else {
            try {
                const response = await axios.post("https://nakmat.online/verify/phone", { phone: phoneNumber, countryNumber: 82 });
                if (response.data.result.code === "SUCCESS") {
                    // 유저가 존재할 경우 인증 코드 발송 요청
                    await handleSendCode();
                } else {
                    // 유저가 존재하지 않을 경우 메시지 표시
                    setError("휴대폰번호를 확인해 주세요");
                }
            } catch (err) {
                setError("관리자에게 문의해 주시길 바랍니다.");
            }
        }
    };

    const handleSendCode = async () => {
        try {
            const response = await axios.post("https://nakmat.online/user/send-join-sms", { phone: phoneNumber, countryNumber: 82 });
            if (response.data.result.smsResult === true) {
                setIsCodeSent(true);
                setIsResend(true);
                setVerificationMessage("발송완료");
                setCountdown(180); // 타이머 초기화
                setError("");
            } else if (response.data.result.smsResult === false) {
                setIsCodeSent(false);
                setVerificationMessage("인증요청");
                setError("휴대폰번호를 확인해 주세요");
            }
        } catch (err) {
            setError("관리자에게 문의해 주시길 바랍니다.");
        }
    };

    const handleVerifyCode = async () => {
        try {
            const response = await axios.post("https://nakmat.online/user/check-sms-code", {
                phone: phoneNumber,
                randomNum: verificationCode,
                countryNumber: 82,
            });
            if (response.data.result.status === true) {
                setIsVerified(true);
                setError("");
                fetchUserInfo();
            } else {
                setError("인증번호가 잘못되었습니다. 다시 확인해 주세요.");
            }
        } catch (err) {
            setError("관리자에게 문의해 주시길 바랍니다.");
        }
    };

    const fetchUserInfo = async () => {
        try {
            const response = await axios.get("https://nakmat.online/api/user", { params: { phone: phoneNumber } });
            if (response.data.result.code === "SUCCESS") {
                setUserInfo(response.data.result.data); // 유저 정보를 상태 변수에 저장
                setShowUserInfo(true);
            } else {
                setError("유저 정보를 가져오는데 실패했습니다.");
            }
        } catch (err) {
            setError("유저 정보를 가져오는 중 오류가 발생했습니다.");
        }
    };

    const handleCheckboxChange = (checked) => {
        setIsChecked(checked);
    };

    const handleDeleteAccount = async () => {
        try {
            const response = await axios.delete("https://nakmat.online/api/user", { data: { id: userInfo.id } });
            if (response.data.result.code === "SUCCESS") {
                // 탈퇴 성공 시 처리
                openIsModal();
            } else {
                setError("탈퇴에 실패했습니다.");
            }
        } catch (err) {
            setError("탈퇴 중 오류가 발생했습니다.");
        }
    };

    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            setPhoneNumber(value);
        }
    };

    const handleVerificationCodeChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            setVerificationCode(value);
        }
    };

    useEffect(() => {
        let timer = null;

        if (isCodeSent && countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdown === 0 && isVerified === false) {
            clearInterval(timer); // 타이머 정리
            setVerificationMessage("재발송");
            setIsCodeSent(false);
            setVerificationCode("");
        }

        return () => {
            if (timer) {
                clearInterval(timer); // 타이머 정리
            }
        };
    }, [isCodeSent, countdown, isVerified]);

    const formatSeconds = (seconds) => {
        return seconds < 10 ? `0${seconds}` : seconds;
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat("ko-KR", { style: "currency", currency: "KRW" }).format(value);
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    };

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };
    const closeIsModal = () => {
        setPhoneNumber("");
        setVerificationCode("");
        setIsCodeSent(false);
        setIsVerified(false);
        setError("");
        setVerificationMessage("인증요청");
        setCountdown(180);
        setIsResend(false);
        setUserInfo(null);
        setIsChecked(false);
        setShowUserInfo(false);
        setIsModalOpen(false);
    };

    const confirmDelete = () => {
        handleDeleteAccount();
        closeModal();
    };

    const openIsModal = () => {
        setIsModalOpen(true);
    };

    const openKakaoChat = () => {
        window.open("http://pf.kakao.com/_xmGqIb", "_blank"); // 카카오톡 채널 URL
    };

    return (
        <div className="App">
            <div className={`${showUserInfo ? "wep-log-small" : "wep-log"}`}>
                <h3 className={`${showUserInfo ? "wep-logt-small" : "wep-logt"}`}>낚시의 맛에 빠지다</h3>
                <img
                    className={`${showUserInfo ? "wep-img-small" : "wep-img"}`}
                    src={"https://storage.googleapis.com/nakmat_fishs/admin-wep/logo_main2.png"}
                    alt="nct"
                />
            </div>
            {!userInfo && !isVerified && (
                <div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <div style={{ textAlign: "left" }}>
                            <div style={{ fontSize: 13, fontFamily: "Nanum Gothic", marginBottom: 10 }}>
                                <label>휴대폰번호</label>
                            </div>
                            <div
                                style={{
                                    marginBottom: "10px",
                                    width: "500px",
                                    height: 40,
                                    backgroundColor: "#f0f0f0",
                                    padding: "10px",
                                    borderRadius: "10px", // 각 끝에 라운드 처리
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                                    <span style={{ marginRight: 15, fontSize: 14, fontFamily: "Nanum Gothic" }}>+82</span>
                                    <input
                                        type="text"
                                        value={phoneNumber}
                                        onChange={handlePhoneNumberChange}
                                        disabled={isCodeSent || isVerified}
                                        maxLength={11}
                                        style={{
                                            flex: "1",
                                            border: "none",
                                            outline: "none",
                                            borderBottom: "1px solid #ccc",
                                            fontSize: "20px",
                                            backgroundColor: "transparent",
                                            textAlign: "left",
                                            marginRight: 15,
                                        }}
                                    />

                                    <button
                                        onClick={handleVerifyPhone}
                                        disabled={isCodeSent || !phoneNumber}
                                        style={{
                                            height: 30,
                                            color: phoneNumber.length >= 10 ? "#2196F3" : "gray",
                                            border: "none",
                                            outline: "none",
                                            ontSize: 20,
                                            borderRadius: "10px",
                                            fontFamily: "Nanum Gothic",
                                            backgroundColor: "transparent", // 배경을 투명하게 설정
                                            transition: "background-color 0.3s ease-in-out", // 호버 효과를 위한 트랜지션 설정
                                        }}
                                        onMouseOver={(e) => {
                                            if (!isCodeSent) {
                                                e.target.style.backgroundColor = "rgba(33, 150, 243, 0.2)";
                                            }
                                        }} // 호버 시 반투명한 하늘색으로 변경
                                        onMouseOut={(e) => {
                                            e.target.style.backgroundColor = "transparent";
                                        }} // 마우스 아웃 시 다시 배경을 투명하게 변경
                                    >
                                        {verificationMessage}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {isResend && !isVerified && (
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <div style={{ textAlign: "left" }}>
                                <div style={{ fontSize: 13, fontFamily: "Nanum Gothic", marginBottom: 10 }}>
                                    <label>인증번호</label>
                                </div>
                                <div
                                    style={{
                                        marginBottom: "10px",
                                        width: "500px",
                                        height: 40,
                                        backgroundColor: "#f0f0f0",
                                        padding: "10px",
                                        borderRadius: "10px", // 각 끝에 라운드 처리
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                                        <input
                                            type="text"
                                            value={verificationCode}
                                            disabled={countdown < 1}
                                            onChange={handleVerificationCodeChange}
                                            maxLength={6}
                                            style={{
                                                flex: "1",
                                                border: "none",
                                                outline: "none",
                                                borderBottom: "1px solid #ccc",
                                                fontSize: "20px",
                                                backgroundColor: "transparent",
                                                textAlign: "left",
                                                marginRight: 25,
                                            }}
                                        />
                                        <div style={{ marginTop: "10px", fontSize: 13, color: "red", fontFamily: "Nanum Gothic" }}>
                                            {countdown > 0 ? `${Math.floor(countdown / 60)}:${formatSeconds(countdown % 60)}` : "만료됨"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button
                                    onClick={handleVerifyCode}
                                    disabled={!verificationCode || countdown < 1}
                                    style={{
                                        marginTop: 15,
                                        height: 50,
                                        backgroundColor: verificationCode.length >= 6 ? "rgba(245, 199, 29)" : "rgba(245, 199, 29, 0.5)",
                                        width: 520,
                                        padding: "10px",
                                        color: "#fff",
                                        border: "none",
                                        outline: "none",
                                        ontSize: 20,
                                        borderRadius: "10px",
                                        fontFamily: "Nanum Gothic",
                                    }}
                                >
                                    인증완료
                                </button>
                            </div>
                        </div>
                    )}

                    {isVerified && <div>Phone number verified successfully!</div>}

                    {error && <div style={{ color: "red" }}>{error}</div>}
                </div>
            )}
            {userInfo && (
                <div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <div
                            style={{
                                width: 200,
                                height: 200,
                                borderRadius: "50%",
                                border: "1px solid black",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: userInfo.avatar ? "transparent" : "white",
                                overflow: "hidden", // Add overflow: hidden to handle image overflow
                            }}
                        >
                            {userInfo.avatar.length > 0 ? (
                                <img src={userInfo.avatar} alt="프로필 사진" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                            ) : (
                                <img
                                    src={"https://storage.googleapis.com/nakmat_fishs/admin-wep/default_avatar.png"}
                                    alt="프로필 사진"
                                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                />
                            )}
                        </div>
                        <div style={{ fontSize: 15, fontFamily: "Nanum Gothic", marginTop: 10, marginBottom: 10 }}>
                            <label>{userInfo.name}</label>
                        </div>
                        <div style={{ fontSize: 15, color: "gray", fontFamily: "Nanum Gothic", marginTop: 10 }}>
                            <label>가입일</label>
                        </div>
                        <div style={{ fontSize: 15, fontFamily: "Nanum Gothic", marginTop: 10, marginBottom: 10 }}>
                            <label>{formatDate(new Date(userInfo.createdDate))}</label>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    margin: "0 20px",
                                    padding: 10,
                                }}
                            >
                                <div style={{ fontSize: 15, color: "gray", fontFamily: "Nanum Gothic", marginTop: 10 }}>
                                    <label>보유 포인트</label>
                                </div>
                                <div style={{ fontSize: 20, fontFamily: "Nanum Gothic", marginTop: 10, marginBottom: 10 }}>
                                    <label>{formatCurrency(userInfo.paymentPoint)}</label>
                                </div>
                            </div>
                            <div style={{ height: "80px", width: "2px", backgroundColor: "gray", marginTop: 10, marginBottom: 10 }}></div> {/* 구분선 */}
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "0 20px", padding: 10 }}>
                                <div style={{ fontSize: 15, color: "gray", fontFamily: "Nanum Gothic", marginTop: 10 }}>
                                    <label>보유 상금</label>
                                </div>
                                <div style={{ fontSize: 20, fontFamily: "Nanum Gothic", marginTop: 10, marginBottom: 10 }}>
                                    <label>{formatCurrency(userInfo.pointAmount)}</label>
                                </div>
                            </div>
                        </div>
                        {userInfo.isSubscription ? (
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        padding: 10,
                                        width: 350,
                                        height: "30px",
                                        background: "linear-gradient(90deg, rgba(255,200,0,1) 0%, rgba(0,75,255,1) 100%)",
                                        borderRadius: "10px",
                                        color: "#fff",
                                        fontFamily: "Nanum Gothic",
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                        textDecoration: "none",
                                        transition: "background 0.3s ease-in-out",
                                    }}
                                >
                                    <div>
                                        <img
                                            src="https://storage.googleapis.com/nakmat_fishs/admin-wep/ic_nakmat_pass_horizontal_white.png" // 여기에 실제 이미지 URL을 사용하세요.
                                            alt="fish"
                                            style={{ marginLeft: "5px", width: "90px", height: "20px" }}
                                        />
                                    </div>
                                    <div>구독중</div>
                                </div>
                                <div style={{ fontSize: 15, color: "gray", fontFamily: "Nanum Gothic", marginTop: 10 }}>
                                    <label>{formatDate(new Date(userInfo.passExpiryTime))}까지 낚맛패스 사용이 가능합니다</label>
                                </div>
                                <div style={{ fontSize: 10, color: "red", fontFamily: "Nanum Gothic", marginTop: 10 }}>
                                    <label>
                                        {" "}
                                        현재 낚맛패스를 이용중입니다. 정기 결제로 이용 중인 경우 앱스토어 또는 플레이스토어에서 직접 해지신청 하셔야 구독취소
                                        처리됩니다.
                                    </label>
                                </div>
                            </div>
                        ) : (
                            <div></div>
                        )}

                        <div style={{ marginTop: 20 }}>
                            <div style={{ fontSize: 14, color: "red", fontFamily: "Nanum Gothic", marginTop: 10, marginBottom: 5 }}>
                                <label> 모든 포인트 및 상금, 낚시 데이터가 삭제되며 복구할 수 없습니다.</label>
                            </div>
                            <label style={{ fontSize: 14, fontFamily: "Nanum Gothic", color: "red" }}>
                                정말 탈퇴하시겠습니까?
                                <input type="checkbox" onChange={(e) => handleCheckboxChange(e.target.checked)} style={{ marginLeft: 10 }} />
                            </label>
                        </div>
                    </div>
                    <button
                        disabled={!isChecked}
                        onClick={openModal}
                        style={{
                            marginTop: 20,
                            height: 50,
                            backgroundColor: isChecked ? "#F5C71D" : "rgba(245, 199, 29, 0.5)",
                            width: 520,
                            padding: "10px",
                            color: "#fff",
                            border: "none",
                            outline: "none",
                            fontSize: 20,
                            borderRadius: "10px",
                            fontFamily: "Nanum Gothic",
                            cursor: isChecked ? "pointer" : "not-allowed",
                        }}
                    >
                        탈퇴하기
                    </button>
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        style={{
                            overlay: {
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                            },
                            content: {
                                top: "50%",
                                left: "50%",
                                right: "auto",
                                bottom: "auto",
                                marginRight: "-50%",
                                transform: "translate(-50%, -50%)",
                                width: "400px",
                                padding: "20px",
                                textAlign: "center",
                            },
                        }}
                    >
                        <h2>경고</h2>
                        <p>탈퇴 후 데이터가 복구가 불가합니다. 정말 탈퇴하시겠습니까?</p>
                        <button
                            onClick={confirmDelete}
                            style={{
                                marginTop: 20,
                                height: 40,
                                backgroundColor: "#F5C71D",
                                width: "100%",
                                padding: "10px",
                                color: "#fff",
                                border: "none",
                                outline: "none",
                                fontSize: 16,
                                borderRadius: "10px",
                                fontFamily: "Nanum Gothic",
                                cursor: "pointer",
                            }}
                        >
                            확인
                        </button>
                        <button
                            onClick={closeModal}
                            style={{
                                marginTop: 10,
                                height: 40,
                                backgroundColor: "lightgray",
                                width: "100%",
                                padding: "10px",
                                color: "#000",
                                border: "none",
                                outline: "none",
                                fontSize: 16,
                                borderRadius: "10px",
                                fontFamily: "Nanum Gothic",
                                cursor: "pointer",
                            }}
                        >
                            취소
                        </button>
                    </Modal>
                    <Modal
                        isOpen={isModalOpen}
                        style={{
                            overlay: {
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                            },
                            content: {
                                top: "50%",
                                left: "50%",
                                right: "auto",
                                bottom: "auto",
                                marginRight: "-50%",
                                transform: "translate(-50%, -50%)",
                                width: "400px",
                                padding: "20px",
                                textAlign: "center",
                            },
                        }}
                    >
                        <p>탈퇴가 완료되었습니다. 낚시의 맛을 이용해 주셔서 감사합니다.</p>
                        <button
                            onClick={closeIsModal}
                            style={{
                                marginTop: 20,
                                height: 40,
                                backgroundColor: "#F5C71D",
                                width: "100%",
                                padding: "10px",
                                color: "#fff",
                                border: "none",
                                outline: "none",
                                fontSize: 16,
                                borderRadius: "10px",
                                fontFamily: "Nanum Gothic",
                                cursor: "pointer",
                            }}
                        >
                            확인
                        </button>
                    </Modal>
                </div>
            )}
            <div>
                <button className="service-center" onClick={openKakaoChat}>
                    문의하기
                </button>
            </div>
        </div>
    );
};

export default PhoneVerification;
